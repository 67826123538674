<template>
  <c-box w="full">
    <c-text color="brand.900">{{ label }}</c-text>
    <c-text white-space="pre-line">{{
      value | formatBoolean | formatArray | formatNullOrEmpty
    }}</c-text>
  </c-box>
</template>

<script>
export default {
  name: "GlossaryDetailItemText",
  props: ["label", "value"],
  filters: {
    formatNullOrEmpty(val) {
      if (val == null || val === "") {
        return "-";
      }
      return val;
    },
    formatBoolean(val) {
      if (val === true) return "ya";
      if (val === false) return "tidak";
      return val;
    },
    formatArray(val) {
      if (Array.isArray(val)) return val.join(", ");
      return val;
    },
  },
};
</script>
